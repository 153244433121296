/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'quill/dist/quill.snow.css';
/* 在 index.css 中引入字体 */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Raleway:wght@400;700&family=Montserrat:wght@400;700&family=Lato:wght@400;700&family=Rubik:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

.ql-container.ql-snow {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before
{
    font-family: "Roboto", cursive;;
    content: "Roboto" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before
{
    font-family: "Raleway", cursive;;
    content: "Raleway" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before
{
    font-family: "Montserrat", cursive;;
    content: "Montserrat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before
{
    font-family: "Lato", cursive;;
    content: "Lato" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before
{
    font-family: "Rubik", cursive;;
    content: "Rubik" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Source-Sans-Pro"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Source-Sans-Pro"]::before {
    font-family: 'Source Sans Pro',  monospace;
    content: 'Source Sans Pro' !important;
}

/* Set content font-families */
.ql-font-Roboto {
  font-family: "Roboto";
}
.ql-font-Raleway {
  font-family: "Raleway";
}
.ql-font-Montserrat {
  font-family: "Montserrat";
}
.ql-font-Lato {
  font-family: "Lato";
}
.ql-font-Rubik {
  font-family: "Rubik";
}

.ql-font-Source-Sans-Pro {
  font-family: 'Source Sans Pro', monospace;
}